.twobutton {
    display: inline-block;
    margin-left: 8px;
}

.space {
    display: flex;
    justify-content: space-between;
}

.search-logo {
    height: 15px;
    width: 15px;
}

#myInput {
    background-image: url("../../image/searchIcon.png");
    
    background-position: 10px 10px;
    background-repeat: no-repeat;
    width: 100%;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    border: 1px solid #ddd;
    margin-bottom: 12px;
  }
  