.jadwal-container {
    display: flex;
    flex-grow: 1;

    flex-direction: column;
    /* overflow-x: scroll; */
    /* margin: 0 1%; */
    margin-top: 24px;
}

.waktu > select{
    width: 100px;
}