/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	background-color: #fafafa;
	line-height: 1;
	margin: 0;
	padding: 0;
}
html {
	margin: 0;
	padding: 0;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a,
u {
	text-decoration: none;
}

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

:root {
	--bright-red: #fc182a;
	--dark-red: #b51d21;
	--clicked-bright-red: #ff9494;
	--red-background: #fff4f4;
	--green: #0ba95c;
	--yellow: #ffb619;
	--brown: #cd9772;
	--blue: #3874ab;
}

/* FONT */
* {
	font-family: "Ubuntu", sans-serif;
}
.display {
	font-size: 64px;
}
h1 {
	font-size: 48px;
}
h2 {
	font-size: 32px;
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 24px;
}
h5 {
	font-size: 20px;
}
h6 {
	font-size: 18px;
}

/* BACKGROUND COLOR */
.bg-red {
	background-color: var(--bright-red);
}
.bg-darkred {
	background-color: var(--dark-red);
}
.bg-clickedred {
	background-color: var(--clicked-bright-red);
}
.bg-redbackground {
	background-color: var(--red-background);
}
.bg-green {
	background-color: var(--green);
}
.bg-yellow {
	background-color: var(--yellow);
}
.bg-brown {
	background-color: var(--brown);
}
.bg-blue {
	background-color: var(--blue);
}

button {
	all: none;
	cursor: pointer;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

/* button:focus {
	background: unset;
} */

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-track {
	background-color: none;
}

::-webkit-scrollbar-thumb {
	background-color: #5c5c5c;
	border-radius: 100px;
}

.content {
	display: flex;
	flex-grow: 1;
	box-sizing: border-box;

	/* width: 100%; */
	margin: 0 12px;

	/* overflow-x: auto; */
}


.text-403 {
	margin-top: 17%;
	font-size: 7vw;
	margin-bottom: 0px;
	color: #fafafa;
	text-align: center;
	
}

.text-small-403 {
	color: #fafafa;
	text-align: center;
	
}

.box-403 {
	background-color: #B51D21;
	width: 100%;
	height: 100%;
}

.centerin {
	display: flex;
	justify-content: center;
}
