/* Buttons styles start */
button {
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background: #0069ed;
    color: #ffffff;
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}

button:hover,
button:focus {
    background: #0053ba;
}

button:focus {
    outline: 1px solid #fff;
    outline-offset: -4px;
}

button:active {
    transform: scale(0.99);
}
/* Button styles end */

.btn{
    display: flex;

    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    
    align-items: center;
    padding: 8px 16px;
    flex-direction: column;
    border-radius: 4px;
    width: fit-content;
    
    outline: none;
    transition: 0.1s ease-out;
}

.btn:hover{
    cursor: pointer;
}

.btn-xl{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    padding: 16px 20px;
}

.btn-l{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 16px;
}

.btn-s{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    padding: 8px;
}

.btn-primary{
    color: #ffff;
    background: #B51D21;
    border: none;
}

.btn-primary:hover{
    background-color: #FF9494;
    color: #ffff;
    cursor: pointer;
}

.btn-primary:active{
    background-color: #7A1013;
    color: #ffff;
}

.btn-outline{
    color: #B51D21;
    background: #FFFFFF;
    border: 2px solid #B51D21;
    box-sizing: border-box;
}

.btn-outline-blue{
    color: #3874AB;
    background: #FFFFFF;
    border: 2px solid #3874AB;
    box-sizing: border-box;
}

.btn-outline-blue:hover{
    color: #648DB3;
    border: 2px solid #648DB3 !important;
    cursor: pointer;
}
.btn-outline-blue:active{
    color: #2B5E8C;
    border: 2px solid #2B5E8C !important;
}

.btn-outline:hover {
    color: #FF9494;
    border: 2px solid #FF9494 !important;
    cursor: pointer;
}
.btn-outline:active{
    color: #7A1013;
    border: 2px solid #7A1013 !important;
}

.btn-green{
    background: #0BA95C;
    color: #FFFFFF;
}
.btn-green:hover{
    background: #6ECF91;
    color: #FFFFFF;
}
.btn-green:active{
    background: #008742;
}

.btn-blue{
    background: #3874AB;
    color: #FFFFFF;
    border: 2px solid #3874AB;
}
.btn-blue:hover{
    background: #648DB3;
    color: #FFFFFF;
}
.btn-blue:active{
    background: #2B5E8C;
}

.btn-yellow{
    background: #FFB619;
    color: #FFFFFF;
}
.btn-yellow:hover{
    background: #FAD78E;
    color: #FFFFFF;
}
.btn-yellow:active{
    background: #E19E0E;
}

.btn:disabled{
    color: #BBBBBB;
    background: #F6F6F6;
    cursor: auto;
}

.btn:disabled:hover{
    background: #F6F6F6;
    cursor: auto;
}

.btn:disabled:active{
    background: #F6F6F6;
    cursor: auto;
}



