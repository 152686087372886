table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    table-layout: fixed;
  }
  
  table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }
  
  table tr {
    /* background-color: #f8f8f8; */
    border: 1px solid #ddd;
    padding: .35em;

  }

  tr:nth-child(even) {
    background-color: #FAFAFA;
  }

  td {
    word-wrap: break-word;
    max-width: 350px;
  }
  
  table th,
  table td {
    padding: .625em;
    text-align: left;
  }
  
  table th {
    font-size: .85em;
    letter-spacing: .1em;
    color: white;
    background-color: #B51D21;
    /* text-transform: uppercase; */
  }
  
  @media screen and (max-width: 600px) {
    table {
      border: 0;
      width: 100%;
    }
  
    table caption {
      font-size: 1.3em;
    }
    
    table thead {
      /* border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      display: block;
      width: 1px; */

      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
      margin-bottom: .625em;

    }
    
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
    }
    
    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    table td:last-child {
      border-bottom: 0;
    }
  }
  

  
  
  
  
  
  
  /* general styling */
  /* body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.25;
  } */


.table-none{
    border-collapse: separate;
    border: 1px solid transparent;
}

/* .table-none  td {
    text-align: left;
    padding: 0.5em 1em;
} */


.btn-outline-secondary {
    color: #6c757d;
    border: 2px solid #6c757d;
    box-sizing: border-box;
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.btn-outline-secondary:active{
    color: #fff;
    background-color: #51565a;
    border-color: #51565a;
}

.btn-outline-red{
    color: #B51D21;
    background: #FFFFFF;
    border: 2px solid #B51D21;
    box-sizing: border-box;
}
.btn-outline-red:hover {
    color: #FF9494;
    border: 2px solid #FF9494 !important;
    cursor: pointer;
}
.btn-outline-red:active{
    color: #7A1013;
    border: 2px solid #7A1013 !important;
}


.btn-red{
    color: #FFFFFF;
    background: #B51D21;
    border: 2px solid #B51D21;
    box-sizing: border-box;
}
.btn-red:hover {
    background: #FF9494;
    border: 2px solid #FF9494 !important;
    cursor: pointer;
}
.btn-red:active{
    background: #7A1013;
    border: 2px solid #7A1013 !important;
}

.card-max-width {
    box-shadow: rgba(80, 80, 80, 0.2) -1px 2px 6px 1px;
    width: 100%;

}

.card-content {
    padding: 1.5em 2em;
    margin-bottom: 2em;
    margin-top: 1em;
}
.center {
    text-align: center;
}

.table-max {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
    margin-bottom: 2em;
}

.table-max #head {
    background-color: #B51D21;
    color:white;
}